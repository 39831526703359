<template>
  <Default> </Default>
</template>

<script>
import Default from '@/components/template/register/demo/Default';

export default {
  components: {
    Default
  }
};
</script>
